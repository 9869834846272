// Types for resource catalog

import { fullStyle } from "../util/util";

export type ChVs = {
  ch: number;
  v: number;
}

export enum SermonStyle {
  NARRATIVE = 'Narrative',
  DISCOURSE = 'Discourse',
  HYBRID = 'Hybrid',
  EXPOSITORY = 'Expository',
}

export type Reference = {
  book: string;
  start: ChVs;
  end: ChVs;
}

export enum TimeLineEventType {
  SCRIPTURE = 'scr',
  SECTION = 'sec',
  SUBSECTION = 'sub',
}

export type TimeLineEvent = {
  start: number;
  end?: number;
  type: TimeLineEventType;
  ref?: Reference;
  val?: string;
}

export type TimeLine = TimeLineEvent[];

export type Sermon = {
  title: string;
  id: string;
  ref: string;
  desc: string;
  style: SermonStyle;
  date: Date;
  location: string;
  passage?: string;
  passageBite?: string;
  src?: string; // url for the original
  tags?: string[];
  timeline?: TimeLine;
}

export type Paper = {
  title: string;
  tags: string[];
}

export type Book = {
  title: string;
  synopsis: string;
  imgRef: string; // url for the cover image
  link?: string;
}
