import React, { useRef, useMemo } from 'react';
import { sermons } from '../../../util/catalog';
import WaveSurfer from 'wavesurfer.js';
import { deleteChildren, formateDate, formatTime } from '../../../util/util';
import { Sermon } from '../../../types/catalog';
import loading from '../../../images/loading.gif';
import './extensions.scss';

const waveSurferOptions = (ref: any) => ({
  container: ref,
  height: 60,
  backend: 'WebAudio',
  normalize: true,
})

type MediaPlayerProps = {
  resource: string;
}

type MediaPlayerState = {
  audio?: any;
  ctx: AudioContext;
  duration?: number;
  position?: number;
  playing?: boolean;
  surfer?: WaveSurfer;
}

type SurferProps = {
  url: string;
  onDecode?: (surfer: WaveSurfer, duration: number) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onFinish?: () => void;
  onPlaying?: (duration: number) => void;
  onScroll?: (newTime: number) => void;
}
let gSurfer;
class Surfer extends React.Component<SurferProps> {
  componentDidMount() {
    const { onDecode, onPlay, onPause, onFinish, onPlaying, onScroll } = this.props;
    deleteChildren('surfer');
    const { url } = this.props;
    const surfer = WaveSurfer.create({
      container: '#surfer',
      height: 30,
      normalize: true,
      url,
    })
    this.setState({surfer});
    surfer.on('click', () => {
      surfer.play()
    })
    onDecode && surfer.on('decode', (duration) => onDecode(surfer, duration));
    onPlay && surfer.on('play', () => onPlay())
    onPause && surfer.on('pause', () => onPause())
    onFinish && surfer.on('finish', () => onFinish())
    onPlaying && surfer.on('timeupdate', (newTime) => onPlaying(newTime))
    onScroll && surfer.on('interaction', (newTime) => onScroll(newTime))
    onScroll && surfer.on('seeking', (newTime) => onScroll(newTime))

  }
  render() {
    return <div id="surfer" />;
  }
}

export default class MediaPlayer extends React.Component<MediaPlayerProps, MediaPlayerState> {

  constructor(props: MediaPlayerProps) {
    super(props);
    this.state = {
      ctx: new AudioContext(),
    };
  };

  getDescriptor(): Sermon | undefined {
    const { resource } = this.props;
    return sermons.find((s) => s.id === resource);
  }

  handleDecoded = (surfer: WaveSurfer, duration: number) => {
    this.setState({ surfer, duration })
  }

  handlePlaying = (position: number) => {
    this.setState({ position })
  }

  handleFinish = () => {
    this.setState({
      playing: false,
      position: 0,
    })
  }

  handlePlay = () => {
    const { surfer } = this.state;
    this.setState({playing: true});
    surfer?.play();
  }

  handlePause = () => {
    const {surfer} = this.state;
    this.setState({ playing: false });
    surfer?.playPause();
  }

  render() {
    const { playing, position, duration } = this.state;
    const descriptor = this.getDescriptor();
    if (!descriptor) return null;

    const { ref } = descriptor;
    const url = `/media/${ref}`;
    return (
      <div className="media-player">
        <Surfer
          url={url}
          onDecode={this.handleDecoded}
          onPlaying={this.handlePlaying}
          onPlay={() => this.setState({playing: true})}
          onFinish={() => this.setState({playing: false})}
        />
        <div className="controls">
          {duration ? (
            <>
              <button><img src="/icons/back.svg" height="22" /></button>
              {playing ?
                <button onClick={this.handlePause}><img src="/icons/pause.svg" height="22" /></button>
              :
                <button onClick={this.handlePlay}><img src="/icons/play.svg" height="22" /></button>
              }
              <button><img src="/icons/forward.svg" height="22" /></button>
              {formatTime((position || 0) * 1000)} /
              {formatTime(duration * 1000)}
            </>
          )
          :
            <div className="loading">
              <div className="loading-fingers" /> Loading...
            </div>
          }
        </div>
      </div>
    )

  }
};
