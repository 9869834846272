import React, { ReactNode } from 'react';
import { AppContextInterface, withContext } from '../../app/AppContext';
import { Translation } from '../../../types/firestoreData';
import { sermons } from '../../../util/catalog';
import { mdPathFromPagePath } from '../../../util/util';
import SermonPopup from './SermonPopup';
import './extensions.scss';

type ResourceListProps = {
  context: AppContextInterface
}
type ResourceListState = {
  passages: Array<Translation>;
}
class ResourceListLow extends React.Component<ResourceListProps, ResourceListState> {
  renderPlayer() {
    const { context } = this.props;
    const { param } = mdPathFromPagePath(context.pagePath || '');
    if (!param) {
      return undefined;
    }
    return (
      <div className="player-container" key="player-container">
        <SermonPopup resource={param} />
      </div>
    )
  }

  render() {
    const { context } = this.props;
    const { mdPath } = mdPathFromPagePath(context.pagePath || '');
    const list = sermons.map( (s) => {
      const { title, id, desc } = s;
      return (
        <div key={id} className="sermon-entry">
          <div className="title"><a href={`/${mdPath}/:${id}`}>{title}</a></div>
          <div className="desc">{desc}</div>
        </div>
      );
    });
    return (
      <div className="resource-list">
        {list}
        {this.renderPlayer()}
      </div>
    )
  }
}


const ResourceList = withContext(ResourceListLow);

export default ResourceList;
