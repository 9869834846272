import React from 'react';
import './translation.scss';

type NotesState = {
  showNote: false | number;
}
type NotesProps = {
  notes: Array<any>;
  versesToNotes: Record<number, Array<number>>;
}
class Notes extends React.Component<NotesProps, NotesState> {
  constructor(props: NotesProps) {
    super(props);
    this.state = {showNote: 1};
  }
  handleNoteClick = (evt: any) => {
    const { target } = evt;
    const { id } = target;
    const { showNote: curr } = this.state;
    const idNum = parseInt(id);
    this.setState({
      showNote: curr===idNum ? false : idNum,
    })
  }
  renderNotes() {
    const { notes, versesToNotes } = this.props;
    const { showNote } = this.state;

    const ret: any = [];
    if (!versesToNotes) {
      return null;
    }
    Object.keys(versesToNotes).forEach( (vx: any) => {
      const noteIds: Array<number> = versesToNotes[vx];
      let verseNo = `${parseInt(vx)+1}`;
      noteIds.forEach( (id) => {
        const note = notes[id-1];
        if (note) {
          const { trans, alts } = note;
          const altSpan = alts?.length ? `(${alts})` : '';
          ret.push(
            <div className="note-line">
              <div className="head"><span>{verseNo}</span></div>
              <div className="text"><div className="text"><span className="bold">{trans}</span> {altSpan}</div></div>
            </div>
          )
          let contents = null;
          if (showNote) {
            const { orig, translit, trans, alts, notes, source } = note;
            let processedSource = source?.replace(/\.?\s*\(.*\)/, '.') || '';
            const altSpan = alts?.length ? `(${alts})` : '';
            contents = <> {orig} ({ translit }): {processedSource} { notes }</>;
          }
          ret.push(
            <div className="note-line">
              <div className="head" />
              <div className="text indent">{contents}</div>
            </div>
          )
          verseNo = '';
        }
      })
    })
    return ret;
  }
  render() {

    return (
      <div className="notes">
        {this.renderNotes()}
      </div>
    )
  }
}

export default Notes;