// resource catalog

import { Book, Paper, Sermon, SermonStyle } from "../types/catalog"

export const sermons: Sermon[] = [
  {
    title: 'Are You Free?',
    id: 'areyoufree',
    ref: '20130623AreYouFree.mp3',
    desc: 'Freedom from all the things that enslave us.',
    style: SermonStyle.NARRATIVE,
    passage: 'Phil. 1:12-21',
    passageBite: 'Paul encourages from prison',
    date: new Date("2013-06-23"),
    location: 'Trinity Mennonite Church',
  },
  {
    title: 'The Crowds',
    id: 'crowds',
    ref: '20131020TheCrowds.mp3',
    desc: 'Seeing the desperate need for Jesus in your neighborhood.',
    style: SermonStyle.EXPOSITORY,
    passage: 'Matt. 9',
    passageBite: 'The story of the call of Matthew.',
    date: new Date("2013-10-20"),
    location: 'Trinity Mennonite Church',
  },
  {
    title: 'If God Only Knew',
    id: 'ifonlygod',
    ref: '20150104IfGodOnlyKnew.mp3',
    desc: 'If God only knew who I really am, what would happen? Worthiness and our relationship with God.',
    style: SermonStyle.HYBRID,
    passage: 'Luke 7:36-50',
    passageBite: 'The story of Simon and the sinner.',
    date: new Date("2015-01-04"),
    location: 'Trinity Mennonite Church',
  },
  {
    title: 'If God So Loved',
    id: 'rwanda',
    ref: '20181104Diversity.mp3',
    desc: 'We love (even our enemies) because God so deeply loved us. Originally given during the 2016 presidential election (Trump/Clinton).',
    style: SermonStyle.HYBRID,
    passage: '1 John 4:7-12',
    passageBite: 'This is love; not that we loved God, but God loved us.',
    date: new Date("2015-01-04"),
    location: 'Trinity Mennonite Church',
  },
  {
    title: 'If Rocks Could Cry',
    id: 'cryingrocks',
    ref: '20160320IfRocksCouldCry.mp3',
    desc: `An exploration of the love God using Jesus weeping over Jerusalem as its inspiration. Also the
      inspiration for the name 'Crying Rock Press'!`,
    style: SermonStyle.NARRATIVE,
    passage: 'Luke 19:28-44',
    passageBite: 'The triumphal entry, Jesus weeps over Jerusalem',
    date: new Date("2016-03-20"),
    location: 'Trinity Mennonite Church',
  },
  {
    title: 'Lazarus',
    id: 'lazarus',
    ref: '20171231Lazarus.mp3',
    desc: "Of grief and hope amidst pain. The story of Lazarus, as told from the perspective of Lazarus' dad",
    style: SermonStyle.NARRATIVE,
    passage: 'John 11:1-43',
    passageBite: 'The death and raising of Lazarus',
    date: new Date("2017-12-31"),
    location: 'Trinity Mennonite Church',
  },
  {
    title: 'Thar Be Dragons',
    id: 'dragons',
    ref: '20190707TharBeDragons.mp3',
    desc: "Fear and other dragons we make.",
    style: SermonStyle.DISCOURSE,
    passage: 'Matt 2:1-18',
    passageBite: 'The slaying of the innocents by Herod',
    date: new Date("2019-07-07"),
    location: 'Trinity Mennonite Church',
  },
  {
    title: 'Streams in the Wasteland: Moses',
    id: 'streams',
    ref: '20240424Moses.mp3',
    desc: 'What prevents us from being streams in the waste land?',
    style: SermonStyle.NARRATIVE,
    passage: 'Ex. 3:1-20',
    passageBite: 'The story of the call of Moses.',
    date: new Date("2024-04-24"),
    location: 'Axiom',
  }
]

export const papers: Paper[] = [

]

export const books: Book[] = [
  
]

export const authors: String[] = [
  'ericshenk',
]