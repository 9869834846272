import React, { MouseEvent, ReactElement } from 'react';
import { AppContextInterface, withContext } from '../../app/AppContext';
import { withParams } from '../../../util/util';
import './translation.scss';
import NotesElem from './Notes';
import { fetchChWithNotes, ProcessedSegment, VersesToNotes } from '../../renderer/firestoreUtil';
import { segments2ref } from '../../../util/reference';
import { transformQuote } from '../../../util/util';
import CopyIco from '../../../images/copy-ico.svg';

type Notes = any;
type Passage = {
  id: string;
  book: string;
  ch: string;
  tr: Array<string>;
  notes: Array<Notes>;
}

const VERSES = 0x1;
const NOTES = 0x2;
const PARAGRAPHS = 0x4;
const SPANLESS = 0x8;

let stuff = '';
const logStuff = (evt: any) => {
  const { value } = evt.target;
  stuff = value;
  const out=[];
  for (let ix=0; ix<value.length; ix++) out.push(value.charCodeAt(ix));
  console.log(JSON.stringify(out.map(v => v).join(',')));
}

enum DisplayState {
  Manuscript = SPANLESS,
  Copiable = SPANLESS | PARAGRAPHS,
  Text = PARAGRAPHS,
  Notes = PARAGRAPHS | NOTES,
  Verses = PARAGRAPHS | NOTES | VERSES,
}

const DisplayStateDropDown = (props: any) => {
  const { value, onChange } = props;
  const entries = Object.entries(DisplayState).filter( ([k,v]) => typeof v === 'number');
  const options = entries.map(([k,v]) => <option key={v} value={v}>{k}</option>)
  return (
    <select value={value} onChange={onChange}>
      {options}
    </select>
  )
}

type TranslationPageProps = {
  reference: string;
  context: AppContextInterface;
}
type TranslationPageState = {
  segments: Array<ProcessedSegment>;
  verses2notes: VersesToNotes;
  notes: Array<any>;
  idList: Array<string>;
  displayState: DisplayState;
}

class TranslationPage extends React.Component<TranslationPageProps, TranslationPageState> {
  constructor(props: any) {
    super(props);
    this.fetchData();
  }

  async fetchData() {
    const { context, reference } = this.props;
    const { fb = {} } = context;
    const { db } = fb;
    if (!db) {
      throw new Error('Unable to fetch data from database');
    }
    const {segments, verses2notes, notes, idList=[]} = await fetchChWithNotes(db, reference) || {};
    if (segments && verses2notes) {
      let storedState = window.sessionStorage.getItem('displayState');
      const displayState = storedState === null ? DisplayState.Verses : parseInt(storedState);
      this.setState({
        segments,
        verses2notes,
        notes,
        idList,
        displayState,
      })
    }

  }

  handleDisplayStateChange = (evt: any) => {
    const { value: displayState  } = evt.target;
    this.setState({displayState});
    window.sessionStorage.setItem('displayState', displayState);
  }

  renderVerse(): { elems:Array<React.ReactElement> | null, str: string} {
    const { segments, idList, displayState } = this.state || {};
    const { reference } = this.props;
    if (!segments) {
      return {elems: null, str: ''};
    }
    const [book, ch] = reference.split('_');
    const ref = segments2ref(book, ch, segments);
    const ret: Array<React.ReactElement> = [];
    const refElem = <div className="reference">{ref} {idList.length > 1 ? JSON.stringify(idList) : ''}</div>;
    ret.push(refElem);

    let elems: Array<React.ReactElement> = [];
    let rawText = '';
    let deferredJsx: ReactElement | undefined;
    let className = 'verse-line';
    let lastV=0;

    segments.forEach( (segment, ix) => {
      const { type, v, n } = segment;
      const text = transformQuote(segment.text)
      switch (type) {
        case 'p':
        case 'v': {
          console.log(`type: ${type} v: ${v} lastV: ${lastV}`);
          const skipVerses = type === 'v' && lastV + 1 !== v;
          if (type === 'p' || skipVerses) {
            if (elems.length) {
              if (displayState & PARAGRAPHS) {
                ret.push(<div className={className}>{elems}</div>);
                elems = [];
                rawText += '\n';
              } else {
                elems.push(<span> </span>);
                rawText += ' ';
              }
            }
            className = "verse-line";
          }
          if (type === 'v') {
            lastV = v || 0;
            const jsx = <span className="verse-no">{v}</span>;
            if (displayState & VERSES) {
              deferredJsx = jsx;
            }
            if (!skipVerses) {
              elems.push(<span> </span>); // white space between verses.
              rawText += ' ';
            }
          }
          break;
        }
        case 'n': {
          if (deferredJsx) {
            elems.push(deferredJsx);
            deferredJsx = undefined;
          }
          const v = displayState & NOTES ? <a href='#' id={n}>{text}</a> : <span>{text}</span>;
          elems.push(v);
          rawText += text;
          break;
        }
        case 'l': {
          if (elems.length) {
            if (displayState & PARAGRAPHS) {
              ret.push(<div className={className}>{elems}</div>);
              elems = [];
              rawText += '>>';
            } else {
              elems.push(<span> </span>);
              rawText += ' ';
            }
          }
          className = 'verse-indent-line';
          break;
        }
        case 't': {
          if (deferredJsx) {
            elems.push(deferredJsx);
            deferredJsx = undefined;
          }
          elems.push(<span>{text}</span>);
          rawText += text;
          break;
        }
      }
    })
    if (elems.length) {
      const [book, ch] = reference.split('_');
      ret.push(<div className={className}>{elems}</div>);
    }
    if (displayState == DisplayState.Manuscript) {
      console.log('====== manuscript', rawText);
      return {
        elems:[refElem, <div className="prewrap">{rawText}</div>],
        str: rawText
      }
    }
    if (displayState == DisplayState.Copiable) {
      console.log('====== copiable', rawText);
      return {
        elems: [refElem, <div className="prewrap">{rawText}</div>],
        str: rawText
      }
    }
    console.log('====== other', displayState, DisplayState.Manuscript);
    return { elems: ret, str: rawText };
  }

  handleCopy = (evt: MouseEvent<HTMLButtonElement> ) => {
    const { str } = this.renderVerse();
    navigator.clipboard.writeText(str);
  }

  render() {
    const { verses2notes, notes, displayState } = this.state || {};
    const copyButton = (
      <button onClick={this.handleCopy}><img src={CopyIco}/></button>
    )
    return (
      <div className="content">
        <div><DisplayStateDropDown value={displayState} onChange={this.handleDisplayStateChange} /> {copyButton}</div>
        <div className="translation">
          <div className="translated">
            {this.renderVerse().elems}
          </div>
          <NotesElem versesToNotes={verses2notes} notes={notes}/>
        </div>
      </div>
    )
  }
}

export default withParams(withContext(TranslationPage));
