import React from 'react';
import { Link } from 'react-router-dom';
import MenuBar from './MenuBar';
import './header.scss';
import logo from '../../images/logo.svg';
import { authorRouteHash, mdPathFromPagePath } from '../../util/util';
import { authors } from '../../util/catalog';

type HeaderProps = {
  pagePath: string;
}

class Header extends React.Component<HeaderProps> {

  getHeaderInfo(host: string, author?: string) {
    let tagLine, subTag, menus;
    if (author || host) {
      tagLine = authorRouteHash[author ?? host];
      const prefix = author ? `/${author}` : '';
      menus = [
        [`${prefix}/`, 'Home'],
        [`${prefix}/about`, 'About'],
        [`${prefix}/resources`, 'Resources'],
        [`${prefix}/blog`, 'Blog'],
      ]
    } else {
      tagLine = 'Crying Rock Press';
      subTag = 'If these were silent, the rocks would cry out.';
      menus = [
        ['/', 'Home'],
      ]
    }
    return {tagLine, subTag, menus};
  }

  render() {
    const url = new URL(window.location.href);
    const pagePath = url.pathname;
    const host: string = url.hostname.split('.')[0];
    const isAuthorDomain = authors.includes(host);
    const { author } = mdPathFromPagePath(pagePath);
    const { tagLine, subTag, menus } = this.getHeaderInfo(host, author);
    let imgLink;
    if (url.hostname === 'ericshenk.com') {
      imgLink = <a href="http://cryingrockpress.com/"><img src={logo} /></a>
    } else {
      imgLink = <Link to="/"><img src={logo} /></Link>
    }
    const logoLink = url.hostname === 'ericshenk.com' ? 'cryingrockpress.com/' : '/';
    return (
      <>
        <div className="header">
          <div className="web">
            <div className="web-container">
              <div className="content">
                <div className="logo">{imgLink}</div>
                <div className="tag">
                  <div className="super-tag">
                    {tagLine}
                  </div>
                  <div className="sub-tag">
                    {subTag}
                  </div>
                </div>
                <div className="mb-container">
                  <MenuBar menus={menus} />
                </div>
              </div>
            </div>
            <div className="header-spacer" />
          </div>
          <div className="mobile">
            <MenuBar burger={true} menus={menus}/>
          </div>
        </div>
      </>
    )
  }
}

export default Header;
