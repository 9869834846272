import { addDoc, doc, DocumentData, DocumentReference, Firestore, setDoc } from 'firebase/firestore';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { text } from 'stream/consumers';
import { Translation } from '../../types/firestoreData';

export type ProcessedSegment = {
  type: string;
  text?: string;
  v?: number; // verse number
  n?: string; // note number
}
export type VersesToNotes = {
  [v: number]: Array<number>;
}

function packageData(segments: Array<ProcessedSegment>): any {
  const tr: Array<string> = [];
  let verse: string | undefined;
  segments.forEach((segment) => {
    const { type, text, n } = segment;
    const num = parseInt(n || '0');
    switch (type) {
      case 'v': {
        while (tr.length < num - 1) {
          tr.push('');
        }
        if (verse) {
          tr.push(verse);
        }
        verse = '';
        break;
      }
      case 't': {
        if (text) {
          verse += text;
        }
        break;
      }
      case 'n': {
        verse += `<n id="${num}">${text}</n>`;
        break;
      }
      case 'p': {
        verse += '<p\/>';
        break;
      }
    }
  })
  if (verse?.length) {
    tr.push(verse);
  }
  return tr;
}

function extractData(data: any): { segments: Array<ProcessedSegment>, verses2notes: VersesToNotes } {
  const segments: Array<ProcessedSegment> = [];
  const verses2notes: VersesToNotes = {};
  const ret: Array<React.ReactElement> = [];
  const { tr } = data;
  tr.forEach((verse: string, vx: number) => {
    if (verse?.length) {
      const line: Array<React.ReactElement> = [];
      verses2notes[vx] = [];
      segments.push({ type: 'v', v: vx + 1 })
      let v = verse;
      const regexN = /<n.+?\/n>/;
      const regexP = /<p\/>/;
      let nextIx;
      let ix = 0;
      while ((nextIx = verse.indexOf('<', ix)) >= 0) {
        if (nextIx > ix) {
          segments.push({ type: 't', text: verse.substring(ix, nextIx) })
        }
        const nextChar = verse.charAt(nextIx + 1);
        switch (nextChar) {
          case 'n':
            const results = regexN.exec(verse.substring(nextIx));
            if (results !== null) {
              const { index } = results;
              const val = results[0];
              const matches = val.match(/<n\s+id=['"]?(\d+)['"]?>(.*)<\/n>/);
              if (matches) {
                const [_, id, val] = matches;
                const nid = parseInt(id);
                segments.push({ type: 'n', text: val, n: id });
                verses2notes[vx].push(nid);
              }
              nextIx += val.length;
            }
            break;
          case 'p':
            if (verse.substring(nextIx, nextIx + 4) !== '<p\/>') {
              throw new Error(`invalid p tag ${verse}`);
            }
            segments.push({ type: 'p' });
            nextIx += 4;
            break;
          case 'l':
            if (verse.substring(nextIx, nextIx + 4) !== '<l\/>') {
              throw new Error(`invalid l tag ${verse}`);
            }
            segments.push({ type: 'l' });
            nextIx += 4;
            break;
          default:
            throw new Error(`unknown tag: ${verse.substring(nextIx)}`)
        }
        ix = nextIx;
      }
      if (verse.length > ix) {
        segments.push({ type: 't', text: verse.substring(ix, verse.length) });
      }
    }
  })
  return { segments, verses2notes };
}

export async function writeChWithNotes(db: Firestore, refStr: string, id: string | undefined, segments: Array<ProcessedSegment>, notes: Array<any>): Promise<void> {
  const [book, chStr] = refStr.split('_');
  if (!db) {
    throw new Error('Unable to write data to database (no db)');
  }
  let docRef: DocumentReference<DocumentData>;
  const ch = parseInt(chStr);
  const newDoc = {
    book,
    ch,
    tr: packageData(segments),
    notes,
  }
  if (id) {
    docRef = doc(db, "translations", id);
    await setDoc(docRef, newDoc);
  } else {
    docRef = await addDoc(collection(db, "translations"), newDoc);
  }
}

export async function fetchChWithNotes(db: Firestore, refStr: string) {
  const [book, ch, v] = refStr.split('_');
  if (!db) {
    throw new Error('Unable to fetch data from database (no db)');
  }
  const transCollection = collection(db, "translations");
  const q = query(transCollection, where('book', '==', book), where('ch', '==', parseInt(ch)));
  const snapshot = await getDocs(q);
  if (snapshot.empty) {
    return null;
  }
  const idList = snapshot.docs.map(doc => doc.id);
  const doc = snapshot.docs[0];
  const data = doc.data();
  data.id = doc.id;
  const { segments, verses2notes } = extractData(data) || {};
  return {
    id: data.id,
    idList,
    segments,
    verses2notes,
    notes: data.notes,
  }
}

export async function fetchPassages(db: Firestore) {
  if (!db) {
    throw new Error('Unable to fetch data from database');
  }
  const snapshot = await getDocs(collection(db, "translations"));
  const passages: Array<Translation> = [];
  snapshot.forEach((doc) => {
    const data = doc.data() as Translation;
    data.id = doc.id;
    passages.push(data);
  });
  return { passages };
}