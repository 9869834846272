import React from 'react';
import { sermons } from '../../../util/catalog';
import { formateDate, withLocation, withNavigate } from '../../../util/util';
import './extensions.scss';
import MediaPlayer from './MediaPlayer';
import { useNavigate } from 'react-router-dom';

type SermonPopupProps = {
  resource: string;
  navigate: any;
  location: any;
}

class SermonPopup extends React.Component<SermonPopupProps> {
  constructor(props: SermonPopupProps) {
    super(props);
    this.loadResource();
  };

  getDescriptor() {
    const { resource } = this.props;
    return sermons.find((s) => s.id === resource);
  }

  async loadResource() {
    // const descriptor = this.getDescriptor();
    // if (!descriptor) return;

    // const { ref } = descriptor;

    // const ctx = new AudioContext();
    // let audio;
    // const data = await fetch(`/media/${ref}`);
    // const arrayBuffer = await data.arrayBuffer();
    // const decoded = await ctx.decodeAudioData(arrayBuffer);
    // this.setState({ audio: decoded });
  }

  renderLine(title: string, value?: string, second?: string) {
    if (value === undefined) return null;
    return <>
      <div className="label">{title}</div>
      <div>{value}</div>
      {second ? <div>{second}</div> : null}
    </>
  }

  handleClose = () => {
    const { location, navigate } = this.props;
    const { pathname } = location;
    const path = pathname.split(':')[0];
    navigate(path);
  }

  render() {
    const { resource } = this.props;
    const descriptor = this.getDescriptor();

    if (!descriptor) {
      return (
        <div className="player">Invalid id {resource}</div>
      )
    }
    const { id, title, desc, style, passage, passageBite, date, location } = descriptor;

    return (
      <div className="player">
        <div className="close" onClick={this.handleClose}>X</div>
        <div className="title">{title}</div>
        {this.renderLine('', desc)}
        {this.renderLine('Style', style)}
        {this.renderLine('Passage', passage, passageBite)}
        {this.renderLine('Date', formateDate(date))}
        {this.renderLine('Location', location)}
        <MediaPlayer resource={id} />
      </div>
    )
  }
};

export default withNavigate(withLocation(SermonPopup));