import React, { ReactNode } from 'react';
import { AppContextInterface, withContext } from '../../app/AppContext';
import { Translation } from '../../../types/firestoreData';
import { Link } from 'react-router-dom';
import { lookupBookDef, passage2ref } from '../../../util/reference';
import { fetchPassages } from '../firestoreUtil';

type PassageListProps = {
  context: AppContextInterface
}
type PassageListState = {
  passages: Array<Translation>;
}
class PassageListLow extends React.Component<PassageListProps, PassageListState> {
  constructor(props: any) {
    super(props);
    this.fetchData();
    this.state = {
      passages: [],
    };
  }

  async fetchData() {
    const { fb = {} } = this.props.context;
    const { db } = fb;
    if (!db) {
      throw new Error('Unable to fetch data from database');
    }
    const { passages } = await fetchPassages(db);
    this.setState({
      passages
    })

  }

  renderPassages(): ReactNode {
    const { passages } = this.state;
    const ret: Array<ReactNode> = [];

    passages.forEach((passage) => {
      passage.def = lookupBookDef(passage.book);
      if (!passage.def) {
        console.log(`===== Could not find def for ${passage.book}`)
      }
    })

    passages.sort((a, b) => {
      const { def, ch, tr } = a;
      const { def: defb, ch: chb, tr: trb } = b;
      if ((def?.ix || 0) < (defb?.ix || 0)) {
        return -1;
      }
      if ((def?.ix || 0) > (defb?.ix || 0)) {
        return 1;
      }
      if (ch < chb) {
        return -1;
      }
      if (ch > chb) {
        return 1;
      }
      return 0;
    })
    passages.forEach((passage) => {
      const {context} = this.props;
      const { pagePath } = context;
      const ref = passage2ref(passage);
      const { book, ch } = passage;
      const firstVerse = ref.match(/:(\d+)/)?.[1] || 'err';
      let baseUrl = pagePath?.replace(/(s|\/)+$/g, '');

      const ref2 = `${book}_${ch}_${firstVerse}`;
      ret.push(<div className="passage">
        <Link to={`${baseUrl}/${ref2}`}>{`${ref}`}</Link>
      </div>)
    })
    return ret;
  }
  render() {
    return (
      <div className="passage-list">
        {this.renderPassages()}
      </div>
    )
  }
}
const PassageList = withContext(PassageListLow);

export default PassageList;
