import React from 'react';
import Markdown from 'marked-react';
import extensions from './extensions';
import { mdPathFromPagePath } from '../../util/util';
import './renderer.scss';

const renderer = {
  paragraph(text: string) {
    const t = this;
    const matches = Array.from(text[0].matchAll(/%(\w+)%/g));
    if (matches.length) {
      const name = matches[0][1];
      if (extensions[name]) {
        return extensions[name]();
      } else {
        return `no extension mapping defined for ${name}`;
      }
    }
    const s = Object.getPrototypeOf(t);
    return s.paragraph.apply(this, arguments);
  }
}

type RenderProps = {
  pagePath: string;
  author?: string;
}
type RendererState = {
  mdPath: string;
  md: string;
}

class Renderer extends React.Component<RenderProps, RendererState> {
  mdPath: string = '';
  constructor(props: any) {
    super(props);
    this.state = { md: '', mdPath: '' };
    console.log('===== Renderer')
    this.checkPath();
  }
  loadPage(mdPath: string) {
    console.log(`import ../pages/${mdPath}.md`)
    // @ts-ignore
    import(`../pages/${mdPath}.md`)
      .then((res) => {
        fetch(res.default)
          .then((response) => response.text())
          .then(response => {
            this.setState({ md: response, mdPath })
          })
      }).catch(e => {
        console.log(e);
        this.setState({ md: 'error', mdPath })
      })
  }
  checkPath(): void {
    const { pagePath, author } = this.props;
    const fullPath = author ? `/${author}${pagePath}` : pagePath;
    const { mdPath: newMdPath } = mdPathFromPagePath(fullPath);
    const { mdPath } = this;
    if (mdPath !== newMdPath) {
      this.mdPath = newMdPath;
      this.loadPage(newMdPath);
    }
  }
  render() {
    this.checkPath();
    return (
      <div className="content">
        <div className="render">
          <Markdown value={this.state.md} renderer={renderer} openLinksInNewTab={false} />
        </div>
      </div>
    )
  }
}

export default Renderer;
