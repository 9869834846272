import { FirebaseApp } from 'firebase/app';
import { Firestore } from 'firebase/firestore';
import React from 'react';

export interface FireBaseContextInterface {
  db?: Firestore;
  app?: FirebaseApp;
}
export interface AppContextInterface {
  fb?: FireBaseContextInterface;
  pagePath?: string;
}
const context = React.createContext<AppContextInterface>({});

export function withContext(Component: any) {
  return (props: any) => {
    return <context.Consumer>
      {(context) => <Component context={context} {...props} />}
    </context.Consumer>
  }
}
export default context;
