import React from 'react';
import Header from './Header';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './app.scss';
import Renderer from '../renderer/Renderer';
import { initializeApp } from "firebase/app";
import 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import AppContext from './AppContext';
import Translation from '../pages/Translation';
import { authors } from '../../util/catalog';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDGUU1_192tjfC5xBAY1-_dzeU1b-YUptY",
  authDomain: "erics-1a417.firebaseapp.com",
  projectId: "erics-1a417",
  storageBucket: "erics-1a417.appspot.com",
  messagingSenderId: "893003555539",
  appId: "1:893003555539:web:00ab541f3e0ac13cbeea26",
  measurementId: "G-FBVTZVHLX8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

const RouteContext = React.createContext('');
const withRouter = (Component: any) => {
  let lastLoc: string;
  return (props: any) => {
    const location = useLocation();
    if (location.pathname !== lastLoc) {
      lastLoc = location.pathname;
      props.onRouteChange?.(location.pathname)
    }
    return <RouteContext.Provider value={location.pathname}><Component {...props} /></RouteContext.Provider>;
  }
};
const WithRouter = withRouter((props: any) => {
  return props.children;
});

class App extends React.Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    const url = new URL(window.location.href);
    const pagePath = url.pathname;
    const host: string = url.hostname.split('.')[0];
    const isAuthorDomain = authors.includes(host);
    const author = isAuthorDomain ? host : undefined;
    const firebaseContext = { app, db };
    const context = { fb: firebaseContext, pagePath }
    let translationPath = '/translation/:reference';
    if (!isAuthorDomain) translationPath = `/ericshenk${translationPath}`;
    return (
      <div className="app">
        <AppContext.Provider value={context}>
          <Router>
            <WithRouter>
              <RouteContext.Consumer>{
                (pathname) =>
                  <>
                    <Header pagePath={pagePath} />
                    <div className="body">
                      <Routes>
                        <Route path={translationPath} element={<Translation />} />
                        {
                          isAuthorDomain ?
                            <>
                              <Route path="/" element={<Renderer pagePath={'/ericshenk/home'} />} />
                            </>
                            :
                            <>
                              <Route path="/" element={<Renderer pagePath={'/home'} />} />
                              <Route path="/ericshenk" element={<Renderer pagePath={'/ericshenk/home'} />} />
                            </>
                        }
                        <Route path="/*" element={<Renderer pagePath={pathname} author={author}/>} />
                      </Routes>
                    </div>
                  </>
              }
              </RouteContext.Consumer>
            </WithRouter>
          </Router>
        </AppContext.Provider>
      </div>
    )
  }
}

export default App;
