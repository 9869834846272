import { stringLength } from "@firebase/util";
import { useLocation, useNavigate, useParams } from "react-router-dom"

export const authorRouteHash: Record<string, string> = {
  'ericshenk': 'Eric Shenk',
};

export const withParams = (Component: any) => {
  return (props: any) => {
    const paramsIn = useParams();
    return <Component {...paramsIn} {...props} />
  }
}

// Replaces straight quotes with begin/end quotes
export const transformQuote = (text: string='') => {
  let ret = text;
  ret = ret.replace(/(^|\s)"/gm, "“");
  ret = ret.replace(/"/gm, "”");
  ret = ret.replace(/(^|\s)'/gm, "‘");
  ret = ret.replace(/'/gm, "’");
  return ret;
}

const extractParam = (str: string = '') => {
  if (str[0] === ':') {
    return str.substring(1);
  }
  return undefined;
}

export const mdPathFromPagePath = (pagePath: string) => {
  const [noop, author, page, alt] = pagePath.split('/');
  const isPub = !authorRouteHash[author];
  const param = extractParam(page) ?? extractParam(alt);
  if (isPub) {
    return {mdPath: author, page: author, param};
  }
  const mdPath = `${author}/${page}`;
  return {mdPath, author, page, param};
}

export const formateDate = (date: Date, withDay: boolean = false): string | undefined => {
  if (!date) return undefined;
  const weekday = withDay ? 'long' : undefined;
  return date.toLocaleDateString('en-us', { weekday, year: "numeric", month: "short", day: "numeric" }) 
}

export const formatTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000 + .5);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // Pad the numbers with leading zeros if necessary
  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');
  const secondsStr = seconds.toString().padStart(2, '0');

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

export const fullStyle = () => {
  
}

export const deleteChildren = (nodeId: string) => {
  var node = document.getElementById(nodeId);
  while (node && node.firstChild) {
    node.removeChild(node.firstChild);
  }
}

export const withLocation = (Klass: any) => {
  return (props: any) => {
    const location = useLocation();
    return <Klass {...props} location={location} />
  }
}

export const withNavigate = (Klass: any) => {
  return (props: any) => {
    const navigate = useNavigate();
    return <Klass {...props} navigate={navigate} />
  }
}