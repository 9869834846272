import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import Hamburger from '../../images/hamburger.svg';

type MenuBarProps = {
  menus: Array<any>;
  burger?: boolean;
}
class MenuBar extends React.Component<MenuBarProps> {
  render() {
    const { menus, burger } = this.props;
    if (burger) {
      return <img src={Hamburger} />;
    }
    const lis = menus.map(([link, name]) => <li key={link}><Link to={link}>{name}</Link></li>)
    return (
        <ul>
          {lis}
        </ul>
    )
  }
}

export default MenuBar;
